@import "xixia";

.transparent {

  background: none;
}

#masthead {
	  text-align: center;
	  color: #fff;
    margin-bottom: 0px;
    background-image: url('/assets/images/cropped-slide-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

h1.lead {
  font-size: 2.25rem;

}

p.small {
  font-size: 1.15rem;
  font-weight: 400;
}

nav.transparent {
    background: none;
}

.custom-logo {
    max-height: 60px;
    width: auto;
    height: auto;
}

.nav-link:hover, .nav-link:focus {
  /* text-decoration: none; */

  color: rgb(0, 255, 0) !important;
}

.well {
  border-radius: 15px;
  background: #ffffff;
  padding: 20px; }


/* post style */
.article {
  font-size: 17px;
  color: #28323C; }

.article img {
  max-width: 100%; }

.ansible-role-logo {
    max-height: 60px;
}

figure.highlight > pre {
  font-size: 0.8rem;
  background: #eeeeee;
}

.techtiles_category {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3A5733;
}