
html {
    height:100%;
}

body {
    // background-image: url(/assets/images/canvas_bg.jpg);
    // padding-top: 60px;
    height:100%;
    // background-color:#ade3ff;
    background-color:#E0E0E0;
}

// .wrap {
//     position:relative;
//     min-height:100%;
// }

.footer {
    text-align: center;
    color: $footer-color;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    min-height:40px;
    background-color:$footer-background-color;
}

.footer a {
    /* color: #aaa; */
    color: $footer-a-color;
}

.footer a:hover {
    color: $footer-a-hover-color;
}

.footer p {
    margin-top:10px;
}

.container-left {
    margin: 0;
    padding-bottom:60px;
}

.well {
    border-radius: 15px;
    background: $article-background-color;
    padding: 20px;
}

/* post style */
.article {
    font-size: 17px;
    color: $article-color;
}

.article img {
    max-width:100%;
}

/* set the link style */
.article a {
	color: $article-a-color;
}

.article a:hover {
	color: $article-a-hover-color;
}

.icon-style {
    font-size: 13px;
}

.post-date {
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 3px;
}

/*
    custom bootstrap navbar
    reference from: https://stackoverflow.com/questions/18529274/change-navbar-color-in-twitter-bootstrap-3
*/
.navbar-custom {
    background-color: $nav-bar-background-color;
    color: $nav-bar-color;
    border-radius:0;
}

.navbar-custom .navbar-nav > li > a {
    color: $nav-bar-color;
}

.navbar-custom .navbar-nav > .active > a {
    color: $nav-bar-color;
    background-color:transparent;
}

.navbar-custom .navbar-nav > li > a:hover,
.navbar-custom .navbar-nav > li > a:focus,
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus,
.navbar-custom .navbar-nav > .open >a {
    text-decoration: none;
    background-color: $nav-bar-open-a-background-color;
}

.navbar-custom .navbar-brand {
    color: $nav-bar-brand-color;
}

.navbar-custom .navbar-brand:hover {
    color: $nav-bar-brand-hover-color;
}

.navbar-custom .navbar-toggle {
    background-color:#eeeeee;
}
.navbar-custom .icon-bar {
    background-color: $nav-bar-icon-bar;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote p {
  display: inline;
}

// .box {
//     margin-bottom: 20px;
//     height:300px;
//     // background:#BFBFBF;
// }

// @media (min-width: 992px) {
//   .equal{
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//   }
// }
// .panel {
//     // width: 100%;
//     height: 100%;
// }

a {
  -webkit-transition: .25s all;
  transition: .25s all;
}

.card {
  overflow: hidden;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: .25s box-shadow;
  transition: .25s box-shadow;
}

.card:focus,
.card:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.58), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
// background:rgba(0,0,0,);
border:1px solid #fff;
}

.card-inverse .card-img-overlay {
  background-color: rgba(51, 51, 51, 0.85);
  border-color: rgba(51, 51, 51, 0.85);
}

a.card-block { color: black; }

#mapid { height: 360px; }
